:root {
  --color-white: #ffffff;
  --color-black: #000000;
  --color-primary: #003284;
  --color-red: #ff0000;
  --color-primary-light: #154797;
  --color-grey-light: #f2f2f2;
}
/*breakpoint mixin start here*/
@screen1450: ~'only screen and (max-width: 1450px)';
@screen1150: ~'only screen and (max-width: 1150px)';
@screen1024: ~'only screen and (max-width: 1024px)';
@screen991: ~'only screen and (max-width: 991px)';
@screen767: ~'only screen and (max-width: 767px)';
@screen540: ~'only screen and (max-width: 540px)';
@screen375: ~'only screen and (max-width: 375px)';
@screen320: ~'only screen and (max-width: 320px)';
/*breakpoint mixin end here*/
